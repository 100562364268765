import routerOptions0 from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.1_@types+node@22.13.15_db0@0.3.1_eslint@8.57.1_ioredis@_80ba2cd156219ceafae959f90c88879f/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}