import validate from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.1_@types+node@22.13.15_db0@0.3.1_eslint@8.57.1_ioredis@_80ba2cd156219ceafae959f90c88879f/node_modules/nuxt/dist/pages/runtime/validate.js";
import pv_45global from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/middleware/pv.global.ts";
import redirect_45global from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/middleware/redirect.global.ts";
import manifest_45route_45rule from "/home/jenkins/agent/workspace/platform-static front-homepage-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.1_@types+node@22.13.15_db0@0.3.1_eslint@8.57.1_ioredis@_80ba2cd156219ceafae959f90c88879f/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  pv_45global,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}